import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {useFilmList} from '../../Hooks/useFilmList';
import {SectionHeader} from '../Landing/SectionHeader';
import {useOutlet} from 'reconnect.js';
import FilmItem from '../Landing/FilmItem';
import {navigate} from 'gatsby';
import {Breakpoints, Color} from '../../constants';
import Spinner from '../../Components/Spinner';
import {FlexRowEndBetween} from '../../widget';
import Fuse from 'fuse.js';
import moment from 'moment';
import {BlockSelector} from '../../Components/BlockSelector';

function FilmListDesktopContent(props) {
  const {filmList} = props;
  return (
    <section>
      {filmList.map((f, i) => (
        <FilmItem
          onClick={async () => {
            await navigate(`/film?id=${f.id}`);
          }}
          style={{margin: '0 5px 15px 5px'}}
          key={i}
          film={f}
        />
      ))}
      {new Array(5).fill(0).map((f, i) => (
        <div
          style={{width: 222, height: 1, margin: '0 5px 15px 5px'}}
          key={i}
        />
      ))}
    </section>
  );
}

const FILM_FILTER_TYPE = {
  started: 'started',
  coming: 'coming',
  bulkSearch: 'bulkSearch',
};

const FILM_FILTER_TYPE_DISPLAY = {
  started: {
    title: '現正上映',
  },
  coming: {
    title: '即將上映',
  },
  bulkSearch: {
    title: '場次查詢',
  },
};

const FilmList = (props) => {
  const [dimension] = useOutlet('dimension');
  const {isLoading, filmList} = useFilmList({cinemaId: 1001});
  const [result, setResult] = useState([]);
  const [keyword, setKeyword] = useState('');
  const isSearching = !!keyword;
  const fuse = useRef(null);

  const [active, setActive] = useState(FILM_FILTER_TYPE.started);

  const filmListFilterByFilmStatus = useMemo(() => {
    if (active === FILM_FILTER_TYPE.bulkSearch) {
      return [];
    }
    if (active === FILM_FILTER_TYPE.started) {
      return filmList.filter((f) => {
        return moment(f.openingDate).isBefore(moment(new Date()));
      });
    } else if (active === FILM_FILTER_TYPE.coming) {
      return filmList.filter((f) =>
        moment(f.openingDate).isAfter(moment(new Date())),
      );
    }
  }, [active, filmList]);

  useEffect(() => {
    if (active === FILM_FILTER_TYPE.bulkSearch) {
      navigate('/bulk-search');
    }
  }, [active]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    fuse.current = new Fuse(filmListFilterByFilmStatus, {
      findAllMatches: true,
      isCaseSensitive: false,
      keys: ['caption.title', 'caption.subtitle'],
    });
  }, [filmListFilterByFilmStatus, isLoading]);

  const onChange = (e) => {
    const {value} = e.target;
    setKeyword(value);
    setResult(
      fuse.current.search(value).map((r) => ({
        ...r.item,
      })),
    );
  };

  const renderList = isSearching ? result : filmListFilterByFilmStatus;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      {dimension.rwd === 'desktop' && (
        <Fragment>
          <div className="desktop-header">
            <SectionHeader
              title={FILM_FILTER_TYPE_DISPLAY[active].title}
              caption="movies"
            />
            <BlockSelector
              style={{width: 335, height: 35, marginBottom: 0}}
              active={active}
              setActive={setActive}
              options={Object.keys(FILM_FILTER_TYPE).map((t) => ({
                name: FILM_FILTER_TYPE_DISPLAY[t].title,
                value: t,
              }))}
            />
            <Input
              type="text"
              placeholder="請輸入電影名稱"
              onChange={onChange}
            />
          </div>

          <FilmListDesktopContent filmList={renderList} />
        </Fragment>
      )}

      {dimension.rwd === 'mobile' && (
        <Fragment>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <Input
              type="text"
              placeholder="請輸入電影名稱"
              onChange={onChange}
              style={{width: '100%', margin: '10px 0'}}
            />
            <BlockSelector
              style={{width: '100%'}}
              active={active}
              setActive={setActive}
              options={Object.keys(FILM_FILTER_TYPE).map((t) => ({
                name: FILM_FILTER_TYPE_DISPLAY[t].title,
                value: t,
              }))}
            />
          </div>
          <div className="film-list">
            {renderList.map((f, i) => (
              <FilmItem
                key={i}
                film={f}
                onClick={async () => {
                  await navigate(`/film?id=${f.id}`);
                }}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  padding: 0,
                  margin: '10px 0',
                }}
              />
            ))}
          </div>
        </Fragment>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  & > section {
    margin: 24px 0 44px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  & > .desktop-header {
    ${FlexRowEndBetween};
    margin: 50px 0 30px 0;
  }
  @media screen and (max-width: ${Breakpoints.md}px) {
    & > .film-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;

const Input = styled.input`
  border: none;
  background-color: ${Color.black_10};
  border-radius: 10px;
  height: 35px;
  padding: 0 10px;
  outline: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`;
export default FilmList;
