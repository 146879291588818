import React from 'react';
import styled from 'styled-components';
import {Breakpoints, Color, FILM_RATING_DISPLAY} from '../../constants';
import {Card} from '../../widget';
import {useOutlet} from 'reconnect.js';
import {Badge} from '../../Components/Badge';
import * as L from '../../Utils/Lang';

const FilmItem = ({film, onClick, style = {}}, ref) => {
  const [dimension] = useOutlet('dimension');
  return (
    <Card ref={ref} style={style} onClick={onClick} clickable>
      <StyledFilmItem innerWidth={dimension.innerWidth}>
        <img src={film.image} alt="film" />
        <div className="title">{film.caption.title}</div>
        <div className="caption">{film.caption.subtitle}</div>
        <Badge
          style={{
            width: 45,
            marginTop: 10,
            marginBottom: 15,
            backgroundColor: FILM_RATING_DISPLAY[film.rating].color,
          }}>
          {L.d(FILM_RATING_DISPLAY[film.rating].title)}
        </Badge>
      </StyledFilmItem>
    </Card>
  );
};

export default React.forwardRef(FilmItem);

const StyledFilmItem = styled.div`
  & > img {
    width: 200px;
    height: ${200 * (300 / 211)}px;
    border-radius: 10px;
    margin-bottom: 15px;
    object-fit: cover;
    background-color: ${Color.orange};
    display: block;
  }
  & > .title {
    font-size: 18px;
    line-height: 1.57;
    margin-bottom: 2px;
    text-align: left;
  }
  & > .caption {
    font-size: 12px;
    line-height: 1.57;
    color: ${Color.black_50};
    text-align: left;
  }
  & > .title,
  .caption {
    max-width: 130px;
  }
  @media screen and (max-width: ${Breakpoints.md}px) {
    & > img {
      width: ${({innerWidth}) => `${innerWidth / 2 - 20 * 2}px`};
      height: ${({innerWidth}) =>
        `${(innerWidth / 2 - 20 * 2) * (300 / 211)}px`};
    }
  }
`;
